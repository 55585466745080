import { CheckIcon } from "@radix-ui/react-icons";
import { useFetcher } from "@remix-run/react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { Command as CommandPrimitive } from "cmdk";
import { ChevronDown, Loader2, X } from "lucide-react";
import {
  type KeyboardEvent,
  type MouseEventHandler
  ,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { Badge } from "#app/components/ui/badge.tsx";
import { Command, CommandGroup, CommandItem, CommandList } from "#app/components/ui/command.tsx";
import { cn } from "#app/utils/misc.tsx";

import { type InputProps } from "./input.tsx";
export type Items = Array<{ label?: any, value: any, disabled?: boolean }>


export type SelectMultiProps =
  React.InputHTMLAttributes<HTMLInputElement> & {
    multiple?: boolean;
    options?: Items;
    oneOptionAutoSelect?: boolean;

    forceValue?: string | string[];

    defaultValue?: string | readonly string[] | number;
    placeholder?: string;
    withCheckbox?: boolean;
    onSelectionChange?: (value: readonly string[]) => void;
    inputProps?: InputProps,
    disabled?: boolean;
    closeOnSelect?: boolean
  } & ({
    options: Items;
    asyncOptions?: never
  } | {
    options?: never,
    asyncOptions: {
      initialOptions?: Items,
      url: string,
      params: Record<string, any>
    }
  })


export function SelectMulti({
  options,
  asyncOptions,
  placeholder = "Seleziona...",
  defaultValue = [],
  withCheckbox,
  onSelectionChange,
  inputProps,
  className,
  closeOnSelect = true,
  multiple,
  disabled,
  oneOptionAutoSelect,
  forceValue,
  ...props
}: SelectMultiProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);

  const fetcher = useFetcher<Items>()

  useEffect(() => {

    if (asyncOptions?.url) {
      fetcher.load(`${asyncOptions.url}?` + new URLSearchParams(asyncOptions.params).toString())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asyncOptions?.url, JSON.stringify(asyncOptions?.params)])

  useEffect(() => {

    if (forceValue !== undefined) {
      setSelected((Array.isArray(forceValue) ? forceValue as string[] : [forceValue as string]).filter(s => s !== ''))
    }
  }, [forceValue])

  const [inputValue, setInputValue] = useState("");

  const [selected, setSelected] = useState<string[]>(Array.isArray(defaultValue) ? defaultValue as string[] : [defaultValue as string]);

  const isSelected = useCallback((item: string) => {
    return selected.includes(item)
  }, [selected]);

  const handleUnselect = useCallback(
    (item: string) => {
      setSelected((prev) => prev.filter((s) => s !== item));
    },
    [setSelected]
  );

  const handleSelect = useCallback(
    (item: string) => {

      if (multiple && isSelected(item)) {
        handleUnselect(item);
        return;
      }

      setInputValue("");
      setSelected((prev) => multiple ? [...prev, item] : [item]);

      closeOnSelect && setOpen(false)
    },
    [isSelected, setSelected, handleUnselect, closeOnSelect, multiple]
  );

  const handleKeyDown = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
    const input = inputRef.current;
    if (input) {
      if (e.key === "Delete" || e.key === "Backspace") {
        if (input.value === "") {
          setSelected((prev) => {
            const newSelected = [...prev];
            newSelected.pop();
            return newSelected;
          });
        }
      }
      // This is not a default behaviour of the <input /> field
      else if (e.key === "Escape") {
        input.blur();
      }
      else {
        setOpen(true)
      }

      /*console.log(e.key)
      if (e.key === "ArrowDown") {
          e.preventDefault()
          rowVirtualizer.scrollToIndex(1300)

      }*/

    }

  }, [setSelected]);


  const [actualOptions, setActualOptions] = useState(options || asyncOptions?.initialOptions || null)

  useEffect(() => {

    if (fetcher.data && fetcher.state === 'idle') {
      setActualOptions(fetcher.data as Items)
    }

  }, [fetcher.data, fetcher.state])


  const selectableOptions = useMemo(() => {
    if (actualOptions === null) return []

    const filteredOptions = actualOptions.filter((o) => inputValue === "" || (o.label || o.value).toLowerCase().includes(inputValue.toLowerCase()))

    return withCheckbox || !multiple ? filteredOptions : filteredOptions.filter((o) => !selected.includes(o.value))
  }, [selected, actualOptions, withCheckbox, inputValue, multiple]);


  useEffect(() => {

    if (actualOptions === null) return


    // if options change (eg. async) and there are selected values, then get only values that are in new options
    const checkSelected = selected.filter(value => actualOptions.find(opt => opt.value === value))

    // if there are no selected values and oneOptionAutoSelect is true, and there is only one option then select the first one
    if (checkSelected.length === 0 && oneOptionAutoSelect && actualOptions.length === 1) {
      setSelected([actualOptions[0]?.value])
    }
    else {
      setSelected(checkSelected)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualOptions])



  const parentRef = useRef<HTMLDivElement>()

  const rowVirtualizer = useVirtualizer({
    count: selectableOptions.length,
    getScrollElement: () => parentRef.current || null,
    estimateSize: (i) => 35,
    overscan: 5,
  })

  const haltEvent: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const firstRender = useRef(true)

  useEffect(() => {
    if (!firstRender.current) {
      onSelectionChange?.(selected)
    }
    firstRender.current = false

  }, [selected, onSelectionChange])



  return (
    <>
      {
        selected.length === 0 ?
          <input type="hidden" value="" {...props} />
          :
          selected.map((item) => (
            <input key={item} type="hidden" value={item} {...props} />
          ))
      }
      <Command
        onKeyDown={handleKeyDown}
        className="overflow-visible bg-transparent"
        shouldFilter={false}
      >
        <div className={cn("group rounded-md border border-input px-3 text-sm ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 flex flex-row", className, disabled && "cursor-not-allowed opacity-50 bg-gray-50")}>
          <div className="flex flex-wrap gap-1 flex-1">
            {selected.map((item) => {

              const selectedItemBadgeString = (actualOptions || []).find((o) => o.value === item)

              return (
                <Badge key={item} variant="secondary" className="my-2">
                  {selectedItemBadgeString?.label || selectedItemBadgeString?.value}
                  <span
                    className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2 cursor-pointer"

                    onMouseDown={haltEvent}
                    onClick={() => !disabled && handleUnselect(item)}
                  >
                    <X className="h-3 w-3 text-secondary-foreground transition-colors hover:text-muted-foreground" />
                  </span>
                </Badge>
              );
            })}
            <div className="flex flex-1 relative">
              <CommandPrimitive.Input
                ref={inputRef}
                onValueChange={setInputValue}

                onBlur={() => setOpen(false)}
                onClick={() => setOpen(true)}
                placeholder={selected.length === 0 ? placeholder : undefined}
                className={cn("my-2 ml-2 flex-1 bg-transparent outline-none placeholder:text-muted-foreground")}
                {...inputProps}
                value={inputValue}
                disabled={disabled}

              />
              <div className="absolute right-0 flex space-x-2 pointer-events-none h-full justify-center">

                <X className="h-4 w-4 opacity-50 cursor-pointer pointer-events-auto m-auto" onClick={(e) => {
                  e.preventDefault()
                  !disabled && setSelected([]);
                }} />
                {fetcher.state === 'loading' ? <Loader2 className="opacity-50 m-auto w-4 h-4 animate-spin" /> : <ChevronDown className="h-4 w-4 opacity-50 pointer-events-none m-auto" />}
              </div>
            </div>
          </div>
        </div>

        <div className="relative mt-2">
          {fetcher.state !== 'loading' && open && (
            <div className="absolute top-0 w-full rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in z-20">
              <CommandGroup className="max-h-[310px] overflow-auto">
                <CommandList>
                  <div
                    ref={parentRef as React.LegacyRef<HTMLDivElement>}
                    className="List"
                    style={{
                      height: `200px`,
                      overflow: 'auto',
                    }}
                    onMouseDown={haltEvent}
                  >
                    <div
                      style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                        width: '100%',
                        position: 'relative',
                      }}
                    >
                      {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                        const option = selectableOptions[virtualRow.index]

                        return (
                          <div
                            key={virtualRow.index}

                            className={virtualRow.index % 2 ? 'ListItemOdd' : 'ListItemEven'}
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: `${selectableOptions[virtualRow.index]}px`,
                              transform: `translateY(${virtualRow.start}px)`,
                            }}
                          >

                            <CommandItem
                              key={virtualRow.index}
                              onSelect={() => handleSelect(option?.value)}
                              onMouseDown={haltEvent}
                              disabled={option?.disabled}
                            >
                              {
                                withCheckbox ?
                                  <div
                                    className={cn(
                                      "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                                      isSelected(option?.value)
                                        ? "bg-primary text-primary-foreground"
                                        : "opacity-50 [&_svg]:invisible"
                                    )}
                                  >
                                    <CheckIcon className={"w-4 h-4"} />
                                  </div>
                                  : null}
                              <span>{option?.label || option?.value}</span>
                            </CommandItem>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </CommandList>
              </CommandGroup>
            </div>
          )}
        </div>
      </Command>
    </>
  );
}

