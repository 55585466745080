import { type FieldMetadata, useFormMetadata, useInputControl } from '@conform-to/react'
import { type SelectProps } from '@radix-ui/react-select';
import React, { useId, useRef } from 'react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "#app/components/ui/select.tsx";
import { cn } from '#app/utils/misc';
import { Checkbox, type CheckboxProps } from './ui/checkbox.tsx'
import { Input } from './ui/input.tsx'
import { Label } from './ui/label.tsx'
import { SelectMulti, type SelectMultiProps } from './ui/select-multi.tsx';
import { Textarea } from './ui/textarea.tsx'


export type ListOfErrors = Array<string | null | undefined> | null | undefined

export function ErrorList({
  id,
  errors,
}: {
  errors?: ListOfErrors
  id?: string
}) {
  const errorsToRender = errors?.filter(Boolean)
  if (!errorsToRender?.length) return null
  return (
    <ul id={id} className="flex flex-col gap-1">
      {errorsToRender.map(e => (
        <li key={e} className="text-[10px] text-foreground-destructive">
          {e}
        </li>
      ))}
    </ul>
  )
}

export function Field({
  labelProps,
  inputProps,
  errors,
  className,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  inputProps: React.InputHTMLAttributes<HTMLInputElement>
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const id = inputProps.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined
  return (
    <div className={className}>
      <Label htmlFor={id} {...labelProps} />
      <Input
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...inputProps}
      />
      <div className="min-h-[32px] px-4 pb-3 pt-1">
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  )
}

export function TextareaField({
  labelProps,
  textareaProps,
  errors,
  className,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  textareaProps: React.TextareaHTMLAttributes<HTMLTextAreaElement>
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const id = textareaProps.id ?? textareaProps.name ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined
  return (
    <div className={className}>
      <Label htmlFor={id} {...labelProps} />
      <Textarea
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...textareaProps}
      />
      <div className="min-h-[32px] px-4 pb-3 pt-1">
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  )
}

export type CheckboxFieldProps = CheckboxProps & {
  name: string
  form: string
  value?: string
}
export function CheckboxField({
  labelProps,
  buttonProps,
  errors,
  className,
}: {
  labelProps: JSX.IntrinsicElements['label']
  buttonProps: CheckboxFieldProps
  errors?: ListOfErrors
  className?: string
}) {
  const { key, defaultChecked, ...checkboxProps } = buttonProps
  const fallbackId = useId()
  const checkedValue = buttonProps.value ?? 'on'
  const input = useInputControl({
    key,
    name: buttonProps.name,
    formId: buttonProps.form,
    initialValue: defaultChecked ? checkedValue : undefined,
  })
  const id = buttonProps.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  return (
    <div className={className}>
      <div className="flex gap-2">
        <Checkbox
          {...checkboxProps}
          id={id}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          checked={input.value === checkedValue}
          onCheckedChange={state => {
            input.change(state.valueOf() ? checkedValue : '')
            buttonProps.onCheckedChange?.(state)
          }}
          onFocus={event => {
            input.focus()
            buttonProps.onFocus?.(event)
          }}
          onBlur={event => {
            input.blur()
            buttonProps.onBlur?.(event)
          }}
          type="button"
        />
        <label
          htmlFor={id}
          {...labelProps}
          className="self-center text-body-xs text-muted-foreground"
        />
      </div>
      <div className="px-4 pb-3 pt-1">
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  )
}


export function SelectField({
  labelProps,
  selectProps,
  errors,
  className,
  options,
}: {
  options: Array<{ label?: React.ReactNode, value: any }>
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  selectProps: Omit<SelectProps, "defaultValue"> & { id?: string, defaulValue?: string | number | readonly string[] }
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const id = selectProps.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  const ref = useRef<any>()

  return (
    <div className={className}>
      <Label htmlFor={id} {...labelProps} />
      <Select
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...selectProps}
      >
        <SelectTrigger id={id} className={cn("h-10 min-w-[200px] w-full")}>
          <SelectValue placeholder={"Seleziona..."} ref={ref} />
        </SelectTrigger>
        <SelectContent side="top">
          {options.map((option, index) => (
            <SelectItem key={`${index}-${option.value}`} value={`${option.value}`}>
              {option.label ?? option.value}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <div className="min-h-[32px] px-4 pb-3 pt-1">
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  )
}

export function useFields<T extends string>(fieldsDeps: readonly T[], formId?: string): { [key in T]: FieldMetadata<any, Record<string, any>, string[]> } { // Record<T[number], FieldMetadata<any, Record<string, any>, string[]>> {

  const form = useFormMetadata(formId)
  const fields = form.getFieldset()
  const res: Partial<any> = {} as const

  for (const field of Array.from(fieldsDeps || [])) {
    res[field] = fields[field]
  }
  return res as { [key in T]: FieldMetadata<any, Record<string, any>, string[]> }
}


export function getIntent(formData: FormData) {
  return formData.get('intent') as string
}


export function SelectMultiField({
  labelProps,
  selectProps,
  errors,
  className,
  ...props
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  selectProps: React.InputHTMLAttributes<HTMLInputElement> & { defaultValue?: string | number | readonly string[] }
  errors?: ListOfErrors
  className?: string
} & SelectMultiProps) {
  const fallbackId = useId()
  const id = selectProps.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  return (
    <div className={className}>
      <Label htmlFor={id} {...labelProps} />
      <SelectMulti
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...selectProps}
        {...props}
      />

      <div className="min-h-[32px] px-4 pb-3 pt-1">
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>

  )
}
